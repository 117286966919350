define("ember-cli-mirage/server", ["exports", "miragejs", "ember-cli-mirage/ember-data", "ember-cli-mirage/utils/ember-data"], function (_exports, _miragejs, _emberData, _emberData2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "defaultPassthroughs", {
    enumerable: true,
    get: function get() {
      return _miragejs.defaultPassthroughs;
    }
  });
  /**
    @hide
  */
  class EmberServer extends _miragejs.Server {
    constructor(options) {
      // Merge models from autogenerated Ember Data models with user defined models
      if (_emberData2.hasEmberData && options.discoverEmberDataModels) {
        options.models = Object.assign({}, (0, _emberData.discoverEmberDataModels)(), options.models);
      }
      super(options);
      if (typeof location !== 'undefined' && location.search.indexOf('mirageLogging') !== -1) {
        this.logging = true;
      }
    }
  }
  _exports.default = EmberServer;
});