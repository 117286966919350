define("ember-svg-jar/inlined/activity-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#8f9ea4\" stroke-linecap=\"round\" stroke-width=\"1.5\"><path d=\"M184 192c35.346 0 64-28.654 64-64s-28.654-64-64-64-64 28.654-64 64c0 23.272 12.421 43.643 30.996 54.845\"/><path d=\"M151.25 151.25v32h-32\" stroke-linejoin=\"round\"/><path d=\"M184 81.369V128l29 17\" stroke-opacity=\".4\"/></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});