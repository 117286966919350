define("ember-power-calendar-utils/unlocalized", ["exports", "date-fns/addDays", "date-fns/addMonths", "date-fns/addWeeks", "date-fns/differenceInMilliseconds", "date-fns/endOfDay", "date-fns/endOfISOWeek", "date-fns/endOfMonth", "date-fns/format", "date-fns/getDay", "date-fns/getISODay", "date-fns/isSameDay", "date-fns/startOfDay", "date-fns/startOfISOWeek", "date-fns/startOfMonth", "date-fns/isAfter", "date-fns/isBefore", "date-fns/startOfWeek", "ember-power-calendar-utils/format-util"], function (_exports, _addDays, _addMonths, _addWeeks, _differenceInMilliseconds, _endOfDay, _endOfISOWeek, _endOfMonth, _format, _getDay, _getISODay, _isSameDay, _startOfDay, _startOfISOWeek, _startOfMonth, _isAfter2, _isBefore2, _startOfWeek2, _formatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.diff = diff;
  _exports.endOf = endOf;
  _exports.endOfWeek = endOfWeek;
  _exports.formatDate = formatDate;
  _exports.getDefaultLocale = getDefaultLocale;
  _exports.getWeekdays = getWeekdays;
  _exports.getWeekdaysMin = getWeekdaysMin;
  _exports.getWeekdaysShort = getWeekdaysShort;
  _exports.isAfter = isAfter;
  _exports.isBefore = isBefore;
  _exports.isBetween = isBetween;
  _exports.isSame = isSame;
  _exports.isoWeekday = isoWeekday;
  _exports.localeStartOfWeek = localeStartOfWeek;
  _exports.normalizeCalendarDay = normalizeCalendarDay;
  _exports.normalizeCalendarValue = normalizeCalendarValue;
  _exports.normalizeDate = normalizeDate;
  _exports.normalizeDuration = normalizeDuration;
  _exports.normalizeMultipleActionValue = normalizeMultipleActionValue;
  _exports.normalizeRangeActionValue = normalizeRangeActionValue;
  _exports.startOf = startOf;
  _exports.startOfWeek = startOfWeek;
  _exports.weekday = weekday;
  _exports.withLocale = withLocale;
  /*eslint no-console: ["error", { allow: ["debug"] }] */

  function unsupported(method, param) {
    for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }
    throw `unsupported parameter: ${method}(${args.join(',')})`;
  }
  function add(date, quantity, unit) {
    switch (unit) {
      case "day":
        return (0, _addDays.default)(date, quantity);
      case "month":
        return (0, _addMonths.default)(date, quantity);
      default:
        throw unsupported('add', ...arguments);
    }
  }
  function formatDate(date, dateFormat) {
    const normalizedFormat = (0, _formatUtil.normalizeDateFormat)(dateFormat);
    return (0, _format.default)(date, normalizedFormat, {
      useAdditionalDayOfYearTokens: false,
      useAdditionalWeekYearTokens: false
    });
  }
  function startOf(date, unit) {
    switch (unit) {
      case "month":
        return (0, _startOfMonth.default)(date);
      case "isoWeek":
        return (0, _startOfISOWeek.default)(date);
      case "week":
        return (0, _startOfWeek2.default)(date);
      case "day":
        return (0, _startOfDay.default)(date);
      default:
        throw unsupported('startOf', ...arguments);
    }
  }
  function endOf(date, unit) {
    switch (unit) {
      case "month":
        return (0, _endOfMonth.default)(date);
      case "isoWeek":
        return (0, _endOfISOWeek.default)(date);
      case "day":
        return (0, _endOfDay.default)(date);
      default:
        throw unsupported('endOf', ...arguments);
    }
  }
  function weekday(date) {
    return (0, _getDay.default)(date);
  }
  function isoWeekday(date) {
    return (0, _getISODay.default)(date);
  }
  function getWeekdaysShort() {
    if (true /* DEBUG */) {
      console.debug("Calling `getWeekdaysShort` with ember-power-calendar-date-fns is discouraged as date-fns has no locale detection implemented. " + "Please overwrite the power-calendar days component `weekdaysShort` method.");
    }
    return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  }
  function getWeekdaysMin() {
    if (true /* DEBUG */) {
      console.debug("Calling `getWeekdaysMin` with ember-power-calendar-date-fns is discouraged as date-fns has no locale detection implemented. " + "Please overwrite the power-calendar days component `weekdaysMin` method.");
    }
    return ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  }
  function getWeekdays() {
    if (true /* DEBUG */) {
      console.debug("Calling `getWeekdays` with ember-power-calendar-date-fns is discouraged as date-fns has no locale detection implemented. " + "Please overwrite the power-calendar days component `weekdays` method.");
    }
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  }
  function isAfter(date1, date2) {
    return (0, _isAfter2.default)(date1, date2);
  }
  function isBefore(date1, date2) {
    return (0, _isBefore2.default)(date1, date2);
  }
  function isSame(date1, date2, unit) {
    switch (unit) {
      case "day":
        return (0, _isSameDay.default)(date1, date2);
      default:
        throw unsupported('isSame', ...arguments);
    }
  }
  function isBetween(date, start, end) {
    // taken from calendar-luxon
    return +start <= +date && +date <= +end;
  }
  function diff(date1, date2) {
    return (0, _differenceInMilliseconds.default)(date1, date2);
  }
  function normalizeDate(date) {
    // as everything is a Date, return it
    return date;
  }
  function normalizeRangeActionValue(val) {
    return {
      date: val.date,
      _date: {
        start: val.date.start,
        end: val.date.end
      }
    };
  }
  function normalizeMultipleActionValue(val) {
    return {
      date: val.date,
      _date: val.date
    };
  }
  function normalizeCalendarDay(day) {
    day._date = new Date(day.date);
    return day;
  }
  function withLocale(locale, fn) {
    return fn(locale);
  }
  function normalizeCalendarValue(value) {
    if (value) {
      return {
        date: value.date,
        _date: value.date ? value.date : undefined
      };
    }
    return {
      date: undefined,
      _date: undefined
    };
  }
  function normalizeDuration(value) {
    if (value === null) {
      return null;
    }
    if (value instanceof Date) {
      return value.getTime();
    }
    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "string") {
      let [, quantity, units] = value.match(/(\d+)(.*)/);
      units = units.trim() || "days";
      const quantityNumber = parseInt(quantity, 10);
      const a = new Date();
      let b;
      switch (units) {
        case "days":
          {
            b = (0, _addDays.default)(a, quantityNumber);
            break;
          }
        case "w":
        case "week":
          {
            b = (0, _addWeeks.default)(a, quantityNumber);
            break;
          }
        default:
          unsupported("normalizeDuration", value);
      }
      return diff(a, b);
    }
  }
  function getDefaultLocale() {
    if (true /* DEBUG */) {
      console.debug("Calling `getDefaultLocale` with ember-power-calendar-date-fns is discouraged as date-fns has no locale detection implemented. " + "Please overwrite the power-calendar service and set your own locale");
    }
    return 'en';
  }
  function localeStartOfWeek() {
    if (true /* DEBUG */) {
      console.debug("Can't detect start of week automatically. Please configure `startOfWeek` in `calendar.days`. See https://ember-power-calendar.com/docs/the-days");
    }
    return 0;
  }
  function startOfWeek(day, startOfWeek) {
    while (isoWeekday(day) % 7 !== startOfWeek) {
      day = add(day, -1, "day");
    }
    return day;
  }
  function endOfWeek(day, startOfWeek) {
    let eow = (startOfWeek + 6) % 7;
    while (isoWeekday(day) % 7 !== eow) {
      day = add(day, 1, "day");
    }
    return day;
  }
});