define("ember-svg-jar/inlined/app-name-crm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.382 19c2.63 0 4.383-1.603 4.383-4.067v-.078c0-.137-.1-.235-.24-.235l-1.852-.078c-.16 0-.239.059-.239.215v.254c0 1.212-.816 2.034-2.052 2.034-1.255 0-2.071-.822-2.071-2.034V9.008c0-1.231.816-2.053 2.071-2.053 1.236 0 2.052.822 2.052 2.053v.235c0 .156.1.235.24.235l1.852-.079c.14 0 .239-.117.239-.254v-.117C8.765 6.584 7.012 5 4.382 5S0 6.623 0 9.106v5.749C0 17.358 1.753 19 4.382 19zm10.14-.156c.14 0 .239-.098.239-.235v-5.553c0-.059.04-.098.1-.098h1.713c.04 0 .08.04.1.078l2.17 5.612a.28.28 0 00.28.196h1.912c.14 0 .2-.06.2-.157 0-.039 0-.078-.02-.117l-2.371-5.749c-.02-.058 0-.117.06-.137 1.374-.528 2.23-1.799 2.23-3.539 0-2.366-1.533-3.989-3.824-3.989h-4.622a.228.228 0 00-.239.235V18.61c0 .137.1.235.24.235zm2.43-7.646h-2.091c-.06 0-.1-.039-.1-.097V7.209c0-.058.04-.097.1-.097h2.091c1.116 0 1.893.801 1.893 2.053 0 1.232-.777 2.033-1.893 2.033zm10.1 7.646c.14 0 .239-.098.239-.235v-9.23c0-.097.08-.116.12-.038l2.11 3.422c.06.117.16.156.28.156h.358c.12 0 .22-.04.28-.156l2.13-3.461c.04-.079.12-.059.12.039v9.268c0 .137.1.235.24.235h1.832c.14 0 .239-.098.239-.235V5.39c0-.137-.1-.235-.239-.235h-1.853c-.12 0-.219.04-.279.157l-2.57 4.106c-.04.078-.079.078-.119 0l-2.61-4.106c-.06-.117-.159-.157-.278-.157h-1.833a.228.228 0 00-.239.235V18.61c0 .137.1.235.24.235z\"/>",
    "attrs": {
      "height": "24",
      "viewBox": "0 0 35 24",
      "width": "35",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});