define("ember-svg-jar/inlined/step-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#fff\" stroke-linejoin=\"round\" stroke-width=\"1.5\"><path d=\"M104 112l72-72.5L224 88\" stroke-opacity=\".4\"/><g stroke-linecap=\"round\"><path d=\"M104 80l48-48 16.56 16.56L184 64l56-56\"/><path d=\"M72 0v112h176M224 8h16v16\"/><path d=\"M208 88h16V72\" stroke-opacity=\".4\"/></g></g>",
    "attrs": {
      "height": "112",
      "viewBox": "0 0 320 112",
      "width": "320",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});