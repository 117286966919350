define("ember-svg-jar/inlined/safari-pinned-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M89.5 1.1c-22.2 1.3-32.6 4.1-48.1 13C23 24.6 7.8 46.3 3.5 68.3.2 84.9-.1 110.1.3 364.5c.3 230 .5 252.8 2 261.5 2.2 12.4 4 17.7 9.5 28.3 7.8 14.9 18.5 25.7 33.1 33.5 10.6 5.7 17.2 7.8 30.4 9.9 10.5 1.7 27.5 1.8 274.7 1.8s264.2-.1 274.7-1.8c13.2-2.1 19.8-4.2 30.4-9.9 14.3-7.6 25.1-18.4 32.7-32.7 5.7-10.6 7.8-17.2 9.9-30.4 1.7-10.5 1.8-27.5 1.8-274.7s-.1-264.2-1.8-274.7c-2.1-13.2-4.2-19.8-9.9-30.4-7.8-14.6-18.6-25.3-33.5-33.1-10.6-5.5-15.9-7.3-28.3-9.5C617.3.8 594.8.6 359.5.5 218.2.4 96.7.6 89.5 1.1zm285.4 281.4c15.9 24.7 29.3 44.9 29.8 44.8.5-.1 19.3-8.8 41.7-19.3 22.5-10.5 41.2-19 41.6-18.8.3.2-4.3 19.4-10.5 42.8-6.1 23.4-15.3 58.6-20.5 78.2l-9.3 35.8H242.3l-2.2-8.3c-14.2-52.8-38.6-148.3-38.1-148.5.4-.1 19.4 8.5 42.1 19.1 30.5 14.4 41.7 19.2 42.5 18.3.6-.6 13.8-21 29.4-45.4 15.6-24.3 28.7-44.1 29.1-44 .5.2 13.9 20.5 29.8 45.3z\"/>",
    "attrs": {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "933.333",
      "height": "933.333",
      "viewBox": "0 0 700 700"
    }
  };
});