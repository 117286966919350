define("ember-svg-jar/inlined/empty-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"><path d=\"M249.598 162.25c.841 0 1.53-.63 1.584-1.429 1.499-22.426-9.118-33.495-32.146-33.495-23.029 0-33.646 11.07-32.148 33.495.054.8.743 1.429 1.584 1.429h61.126zm-30.563-43.2c7.539 0 13.65-6.111 13.65-13.65 0-7.539-6.111-13.65-13.65-13.65s-13.65 6.111-13.65 13.65c0 7.539 6.112 13.65 13.65 13.65z\" stroke-opacity=\".4\"/><path d=\"M216.07 185.25c1.568 0 2.859-1.18 2.959-2.69 2.399-35.97-14.68-53.81-51.528-53.81-36.85 0-53.928 17.84-51.53 53.81.1 1.51 1.391 2.69 2.958 2.69h97.142zm-48.07-68c12.288 0 22.25-9.962 22.25-22.25S180.288 72.75 168 72.75 145.75 82.712 145.75 95s9.962 22.25 22.25 22.25z\" fill=\"#f2f2f2\"/></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});