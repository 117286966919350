define("ember-svg-jar/inlined/step-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"a\" fill=\"#fff\"><rect fill-rule=\"evenodd\" height=\"161\" rx=\"1\" width=\"26\" x=\".837\" y=\".366\"/></mask><g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(40)\"><g stroke-width=\"1.5\"><path d=\"M13.889 110.642L57 100l42-63 42 15.42L182.663 4l41.292 21\" stroke=\"#8f9ea4\" stroke-opacity=\".4\"/><circle cx=\"57\" cy=\"99\" fill=\"#fff\" r=\"3.25\" stroke=\"#d2d8db\"/><circle cx=\"99\" cy=\"37\" fill=\"#fff\" r=\"3.25\" stroke=\"#d2d8db\"/><circle cx=\"142\" cy=\"52\" fill=\"#fff\" r=\"3.25\" stroke=\"#d2d8db\"/><circle cx=\"183\" cy=\"4\" fill=\"#fff\" r=\"3.25\" stroke=\"#d2d8db\"/><rect height=\"145.5\" rx=\"1\" stroke=\"#00172f\" width=\"24.5\" x=\"44.75\" y=\"38.75\"/><rect height=\"176.5\" rx=\"1\" stroke=\"#00172f\" width=\"24.5\" x=\"86.75\" y=\"7.75\"/><rect height=\"124.5\" rx=\"1\" stroke=\"#00172f\" width=\"24.5\" x=\"128.75\" y=\"59.75\"/><g stroke=\"#d2d8db\" transform=\"translate(169.163 23.634)\"><rect height=\"159.5\" rx=\"1\" width=\"24.5\" x=\"1.587\" y=\"1.116\"/><path d=\"M-4 8.42L32.771-9M-4 13.787l36.771-17.42M-4 18.787l36.771-17.42M-4 23.787l36.771-17.42M-4 28.787l36.771-17.42M-4 33.787l36.771-17.42M-4 38.787l36.771-17.42M-4 43.787l36.771-17.42M-4 48.787l36.771-17.42M-4 53.787l36.771-17.42M-4 58.787l36.771-17.42M-4 63.787l36.771-17.42M-4 68.787l36.771-17.42M-4 73.787l36.771-17.42M-4 78.787l36.771-17.42M-4 83.787l36.771-17.42M-4 88.787l36.771-17.42M-4 93.787l36.771-17.42M-4 98.787l36.771-17.42M-4 103.787l36.771-17.42M-4 108.787l36.771-17.42M-4 113.787l36.771-17.42M-4 118.787l36.771-17.42M-4 123.787l36.771-17.42M-4 128.787l36.771-17.42M-4 133.787l36.771-17.42M-4 138.787l36.771-17.42M-4 143.787l36.771-17.42M-4 148.787l36.771-17.42M-4 153.787l36.771-17.42M-4 158.787l36.771-17.42M-4 163.787l36.771-17.42M-4 168.787l36.771-17.42\" mask=\"url(#a)\"/></g></g><rect fill=\"#00172f\" height=\"1.5\" rx=\".75\" width=\"240\" y=\"183.5\"/></g>",
    "attrs": {
      "height": "186",
      "viewBox": "0 0 320 186",
      "width": "320",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});